.drop-container {
  margin: 20px;
}

.facebook {
  background-color: #3b5998;
  border-radius:20px;
  width:fit-content;
  margin: 0 auto;
  margin-top: 20px;
}

.snap {
  background-color:#f6f62d;
  border-radius:20px;
  padding: 10px 20px;
  width:fit-content;
  margin: 0 auto;
  margin-top: 20px;
}

.query-list-container {
  width: 40%;
  height: 200px;
  background-color: #eaeaea;
  border-radius: 10px;
  display: flex;
  margin: auto;
  position:relative;
  margin-top: 20px;
}

.hover-button {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.query-list-count {
  position: relative;
  font-weight: bold;
  font-size: small;
  margin-top: 1px;  
}

.name-list-container-two-columns {
  display: grid;
  grid-template-columns: repeat(2, 250px);
  grid-gap: 0px;
  justify-content: center;
  align-items: center;
}