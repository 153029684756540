/* ScrollTile.css */

/* Keyframes for the scrolling animation */
@keyframes scrollAnimationLeft {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%); /* Assuming duplicate content for a seamless loop */
    }
}

/* Keyframes for the scrolling animation */
@keyframes scrollAnimationRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%); /* Assuming duplicate content for a seamless loop */
    }
}

/* Apply the animation to the .scroll-tile container */
.carousel-left {
    display: flex;
    flex-wrap:nowrap; /* Change to nowrap for a horizontal line of items */
    overflow:visible; /* Hide the scrollbar */
    padding: 10px;
    animation: scrollAnimationLeft 40s linear infinite; /* Adjust time as needed */
}

.carousel-right {
    display: flex;
    flex-wrap:nowrap; /* Change to nowrap for a horizontal line of items */
    overflow:visible; /* Hide the scrollbar */
    padding: 10px;
    animation: scrollAnimationRight 40s linear infinite; /* Adjust time as needed */
}

/* Individual tile styles */
.tile-item {
    flex: 0 0 250px;
    height: 230px;
    margin: 15px;
    background-color: #c40fe02e;
    opacity: 0.7;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #333;
    overflow: hidden;
}

.tile-item.vertical {
    max-width: 800px;
    min-width: 300px;
    height: 100%;
    margin: 0 auto;
    margin-top: 50px;
}

.result-container {
    align-items: center;
    align-content: center;
}

.message-container {
    align-items: center;
    align-content: center;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    margin:10px;
    max-width: 90%;
}

/* styling for result-label */
.result-label {
    font-size: 20px;
    font-weight: bold;
    margin: 5px;
}

.result-item {
    font-size: 40px;
    color: #666;
    align-items: center;
    justify-content: center;
}

.result-item-small {
    font-size: 20px;
    color: #666;
    margin-top: -5px;
    margin-left: 5px;
    margin-bottom: -5px;
}

.result-word-small {
    font-size: 15px;
    color: black;
    font-weight: bold;
    margin: 0;
}

.result-word {
    font-size: 40px;
    color: black;
    font-weight: bold;
    margin: 0;
}

.result-count {
    font-size: 25px;
    color: #666;
    margin: 0;
}

.message-time {
    font-size: 10px;
    color: #666;
    margin: 0;
}