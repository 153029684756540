/* Footer styling */
footer {
    background-color: #393939;
    padding: 30px;
    text-align: center;
}

footer p {
    color: white;
    font-size: 23px;
    margin-bottom: 10px;
    text-align: 'center';
    padding-top: "100px"; 
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 'bold';
}

footer a {
    color: #007bff;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}