/* Container styles */
.gravity-container{
    width: 100%; /* Ensure it takes the full width */
    overflow: hidden; /* Prevents scrollbars if items go out of bounds */
}
  
  /* Item styles */
.gravity-item {
/* Example item styling, can be customized */
    width: 100px; /* Set a fixed width */
    height: 70px; /* Set a fixed height */
    background-color: #f0f0f0; /* Light grey background */
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 40%; /* Make the items circular */
}

.gravity-item-content {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 0.9rem;
    font-weight: bold;
    display: flex;
    align-self: center;
}