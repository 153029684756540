/* /Users/jamesmcmullen/Documents/dev/JD-Solutions/chat-stat/src/screens/HomeScreen.css */
.home {
  background-color: rgba(195, 240, 255, 0.2);
  width:100%;
  overflow: hidden;
}
.home-content {
  padding: 20px;
  border-radius: 10px;
}

.priv-link {
  color: #007bff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}