li {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding:4px;
    font-size: 1rem;
    display: flex;
    margin-top: 10px
}

ol { counter-reset: list-counter;}

ol li:before { counter-increment: list-counter; content: counter(list-counter) ". "; font-weight: bold; align-self: center; margin-right: 10px;}

.p-facebook { 
    font-weight: bold;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 0;
    margin-bottom: 0;
    color: white;
    background-color: #3b5998;
    border-radius: 5px;
    padding-left:3px;
    padding-right:3px;
    width: fit-content;
}

.content-label {
    background-color: white;
    color: black;
    align-self: center;
}

.facebook {
    background-color: #3b5998;
    color: white;
    padding: 10px 20px;
}

.instagram {
    background-color: #c1353e;
    color: white;
    padding: 10px 20px;
    border-radius:20px;
    width:fit-content;
    margin: 0 auto;
    margin-top: 40px;
}

.p-instagram { 
    background-color: #c1353e;
    font-weight: bold;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 0;
    margin-bottom: 0;
    color: white;
    border-radius: 5px;
    width: fit-content;
    padding-left:3px;
    padding-right:3px;
}

.snap {
    background-color: #f6f62d;
    color: grey;
    padding: 10px 20px;
    border-radius:20px;
    width:fit-content;
    margin: 0 auto;
    margin-top: 40px;
}

.p-snap { 
    background-color: #f6f62d;
    font-weight: bold;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 0;
    margin-bottom: 0;
    color: grey;
    border-radius: 5px;
    padding-left:3px;
    padding-right:3px;
    width: fit-content;
}