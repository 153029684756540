.checkbox-container {
    height: max-content;
    max-width: 18rem;
    min-width: 12rem; 
    display: flex;
    background-color: #87b9ef23;
    border-radius: 0.4rem;
    margin-top: 0.6rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1;
}

.checkbox-container:hover {
    background-color: #f2f2f2;
}

.checkbox-container:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
}

.checkbox-containerselected {
    z-index: 1;
    height: max-content;
    max-width: 18rem;
    min-width: 12rem;
    display: flex;  
    border-radius: 0.4rem;
    margin-top: 0.6rem;
    cursor: pointer;
    background-color: rgba(243, 178, 88, 0.3);
    transition: background-color 0.3s ease;
}

.checkbox-containerselectedcustom {
    z-index: 1;
    height: 70px;
    max-width: 18rem;
    min-width: 12rem;
    margin: 0 auto; 
    display: flex;  
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    margin-top: 0.6rem;
    cursor: pointer;
    background-color: rgba(243, 178, 88, 0.3);
    transition: background-color 0.3s ease;
    transition: height 0.3s ease;
    margin-bottom: 40px;
    padding-bottom: 20px;
}

.checkbox-snap {
    height: max-content;
    max-width: 18rem;
    min-width: 12rem;
    margin: 0 auto; 
    flex-direction: column;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1;
    padding-bottom: 20px;
}

.checkbox-container:hover {
    background-color: #f2f2f2;
}

.snap-chat-checkbox-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-top: 10px;
    width: 90%;
    padding-left: 5%;
}

.hover-message {
    flex-direction: column;
    margin:auto;
    background-color: transparent;
    font-size: 10px;
    color: #333;
    width: 200px;
    text-align: center;
    z-index: 0;
}

.hover-participants {
    flex-direction: column;
    margin:auto;
    background-color: transparent;
    font-size: 10px;
    color: #333;
    width: 200px;
    text-align: center;
    z-index: 0;
}