.privacy-content {
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}
.privacy-content p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin-bottom: 10px;
}

.privacy-content a {
    margin: 0px;
    display: inline-block
}