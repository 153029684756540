.drop-zone {
    border: 2px dashed #ccc;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    height: 6rem;
    max-width: 50%;
    min-width: 15rem;
    margin: 0 auto;
    background-color: #f2f2f2;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.drop-zone:hover {
    background-color: #e6e6e6;
}

.drop-zone:active {
    background-color: #d9d9d9;
}

.drop-zone::before {
    content: "Drop files here";
    display: block;
    font-size: 1.2rem;
    color: #999;
    margin-bottom: 0.5rem;
}

.drop-zone img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 0.5rem;
}

.drop-files,
.drag-files {
    flex-direction: column;
    gap: 10px;
}

.drop-files {
    color: #333;
    font-weight: bold;
}

/* Additional styling for the drag-files area */
.drag-files {
    color: #666;
}

.file-count {
    position: relative;
    bottom: 10px;
}