.App {
  text-align: center;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.burger-menu {
  cursor: pointer;
  position: absolute;
  top: 14px;
  left: 5%;
  font-size: 30px;
}

.bm-menu {
  background: #578595;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-menu-wrap {
  position: fixed;
}

.bm-cross {
  background: black;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  flex-direction: column;
  overflow: hidden;
  max-height: 600px;
}

.bm-item {
  display: inline-block;
  color: white;
  width: max-content;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.bm-item:hover {
  background-color: #0056b3;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

header {
  background-color: #ffffff;
  color: #000;
  height: 40px;
  padding-bottom: 35px;
}

.nav-link-logo {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.priv {
  color: #071e37;
  margin-top: 10px;
  font-size: 12px;  
}

.priv:hover {
  text-decoration: underline;
  background-color: transparent;
}

img {
  width: 100px;
  display: flex;
  margin: 0 auto;
}

h1 {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bold;
  font-size: 2.5em;
  text-align: center;

}

.subheading {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: lighter;
  font-size: 1.5em;
  width: 40%;
  min-width: 300px;
  text-align: center;
  margin: 0 auto;
}

.bubble-text {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  margin: 0 auto;
  background-color: #0056b3;
  color: white;
  padding: 3px;
  border-radius: 20px;
  display: inline-block;
}

input {
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  margin: 10px;
  z-index: 1;
}

input:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.home-uploadButton {
  background-color: var(--color-blue);
  color: white;
  width:max-content;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  outline: 1px solid grey;
}

.home-uploadButton:hover {
  background-color: #0056b3;
}

.analyse-button {
  background-color: var(--color-red);
  color: white;
  width:max-content;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
  transition: background-image 0.3s ease;
  text-decoration: none;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.analyser-results {
  margin-top: 50px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.analyse-button:hover {
  background-image: linear-gradient(to right, #ce2331, #ce2331, #ce2331, #85c3da, #3dc2a3, #f3b258, #c40fe02e, #ce2331, #ce2331, #ce2331);
  animation: rainbow 2s linear infinite;
  background-size: 500% 500%; /* Add this line to increase the size of the background gradient */
  background-position: 0% 50%; /* Add this line to set the initial position of the background gradient */
  animation-fill-mode: forwards; /* Add this line to keep the last frame of the animation */
}

@keyframes rainbow {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

@keyframes rainbow {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

:root {
  --color-blue: #85c3da;
  --color-red: #de4d5986;
  --color-green: #3dc2a3;
  --color-yellow: #f3b258;
  --color-purple: #c40fe02e;
}